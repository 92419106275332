* {
  padding: 0px;
  margin: 0px;
}
.wrapper {
  padding: 0 16px;
  background: white;
}

header {
  /*display: flex;*/
  /*justify-content: flex-end;*/
  /*align-items: center;*/
  /*height: 50px;*/
  /*margin-top: 10px;*/
  /*margin-bottom: 20px;*/
  /*position: absolute;*/
  /*width: 100%;*/
  /*padding: 0 16px;*/
  /*box-sizing: border-box;*/

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
  position: absolute;
  width: calc(100% - 32px);
  /* padding: 0 16px; */
  box-sizing: border-box;
  align-self: center;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;

}
.logo {
  padding: 10px;
  background: #EFEFEF;
  font-weight: bold;
  color: black;
  text-decoration: unset;
}
.burger-menu {
  display: flex;
  align-items: center;
}
.burger-menu span{
  margin-left: 5px;
}


.actions-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
  margin-top: 10px;
}
.actions-item-wrapper {
  /*padding: 10px;*/
  border-radius: 10px;
  /*background: #EFEFEF;*/
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;

}
.actions-item-wrapper span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.actions-item-wrapper.left {
  flex:1;
  margin-right: 10px;
}

.actions-item-wrapper.right {
  /*width: 100px;*/
}



.list-wrapper {
  /*padding: 0 16px;*/
}
.list-item {
  background: #F4F3FC;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: block;
  text-decoration: none;
  color: black;
  position: relative;
  padding-right: 34px;
  overflow: hidden;
}
.red-message2 {
  color: white;
  font-size: 13px;
  font-weight: 400;
  background: #F15457;
  height: 20px;
  padding: 2px 5px;
  box-sizing: border-box;
  position: relative;
}
.red-message2-icon{
  position: absolute;
  right: -18px;
  top: 0;
}
.red-message {
  color: white;
  font-size: 13px;
  font-weight: 400;
  background: #F15457;
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  padding: 2px;
  box-sizing: border-box;
  padding-right: 10px;
}
.red-message-icon {
  position: absolute;
  left: -18px;
  top: 0;
 }
.list-item-title {
  font-size: 14px;
  font-weight: 400;
  color: #85808F;
  margin-bottom: 6px;
}
.list-item-center {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.list-item-center span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-right: 8px;
}
.list-item-center span.active:nth-of-type(1) {
  color: #F22F22;
}

.list-item-center span:nth-of-type(2) {
  padding: 2px 6px;
  border-radius: 10px;
  /*background: #C4C4C4;*/
  font-size: 13px;
  font-weight: 400;
}

.list-item-bottom span:nth-of-type(1) {
  font-size: 13px;
  font-weight: 400;
  color: #85808F;
}

.list-item-bottom span:nth-of-type(2) {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-left: 4px;
}

.list-item-bottom p {
  font-size: 13px;
  font-weight: 400;

}


.list-item-bottom span.active:nth-of-type(2) {
  background: #FF9900;
  border-radius: 20px;
  color: white;
  padding: 3px;
}





.categories-wrapper {
  /*padding: 0 16px;*/
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*justify-content: space-between;*/
}

.categories-wrapper-top {
  display: flex;
  justify-content: space-between;
}
.categories-wrapper-center {
  display: flex;
  justify-content: space-between;
}
.categories-wrapper-bottom {
  display: flex;
  justify-content: space-between;
}

.categories-item {
    flex:1;
  background: #DEDAF6;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  color: black;
  overflow: hidden;
  position: relative;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

.categories-item-top {
  width: 100%;
  height: 42px;
  /*background: #A4A4A4;*/
  position: relative;
  border-radius: 4px;
  margin-bottom: 12px;
}

.categories-item-top-count {
  background: white;
  width: 38px;
  border-radius: 13px;
  text-align: center;
  padding: 3px 0;
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 13px;
}

.categories-item-title {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.banner-wrapper {
  /*padding: 0 16px;*/
  margin-bottom: 30px;
}
.banner-wrapper h1{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 15px;

}
.banner {
  width: 100%;
  /*height: 129px;*/
  border-radius: 10px;
  background: #EFEFEF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #C4C4C4;
}
.banner img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}


.absolute-menu-wrapper {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: white;
  left: 0;
  bottom: 0;
  z-index: 99;
  padding: 24px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.nav__item {
  display: flex;
  align-items: center;
}

.menu-wrapper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.menu-items-wrapper {
  overflow-x: scroll;
  flex: 1;
}
.menu-item {
  display: block;
  color: black;
  padding: 15px 0;
  /*border-bottom: 1px solid #C4C4C4;*/
  text-decoration: none;
  color: #85808F;
  font-size: 18px;
  font-weight: 700;
}

.button {
  border-radius: 8px;
  height: 44px;
  background: #EFEFEF;
  border: unset;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
}


.top-title-wrapper {
  display: flex;
  /*padding: 0 16px;*/
  align-items: center;
  margin-bottom: 10px;
}
.top-title-block {
  width: 42px;
  height: 42px;
  background: #A4A4A4;
  border-radius: 6px;
}
.top-title-text {
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin-left: 9px;
  margin-top: 10px;
}

.right-icon {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto auto;
  height: 17px;
}

.title2 {
  color: #555555;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 17px;
}

.card {
  width: 100%;
  height: 170px;
  background: #EFEFEF;
  border-radius: 6px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
}
.card-top {
  display: flex;
  flex-direction: column;
}
.card-bottom {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.card-top span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.card-top span:nth-of-type(2) {
  font-size: 13px;
  font-weight: 600;
  color: #383838;
}
.card-bottom span:nth-of-type(1) {
  font-size: 13px;
  font-weight: 600;
  color: #383838;
}
.card-bottom span:nth-of-type(2) {
  font-size: 13px;
  font-weight: 600;
  color: #383838;
}



.order-item {
  display: flex;
  margin-bottom: 15px;
  /*border-bottom: 1px solid #C4C4C4;*/
  padding-bottom: 20px;
}
.order-item-left {
    width: 60px;
  height: 60px;
  background: #C4C4C4;
}
.order-item-left img{
  width: 100%;
  height: 100%;

}

.order-item-right {
  flex: 1;
  margin-left: 10px;
}
.order-item-title {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 8px;
}
.order-item-price {
  font-size: 13px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 12px;
}
.order-item-bottom {
  display: flex;
  justify-content: space-between;
}
.order-item-bottom span:nth-of-type(1) {
  font-size: 13px;
  font-weight: 400;
  color: #383838;
}
.order-item-bottom span:nth-of-type(2) {
  font-size: 14px;
  font-weight: 500;
  color: black;
}


.method-of-obtaining-wrapper {

}

.method-of-obtaining-text1 {
    font-size: 13px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 6px;
}

.method-of-obtaining-text2 {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 14px;

}

.method-of-obtaining-text3 {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-decoration: underline;
}

.loader-block {
  position: fixed;
  width: 100%;
  height: 100vh;
  top:0;
  left: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #1F3C73 2.1%, #E38C67 60.1%, rgba(217, 217, 217, 0) 100%);

}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Устанавливаем высоту экрана */
}

.loader {
  border: 8px solid #f3f3f3; /* Светлый цвет внешней окружности */
  border-radius: 50%;
  border-top: 8px solid #3498db; /* Синий цвет вращающейся части */
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite; /* Анимация вращения */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.status_ {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 4px 8px;
  background: white;
  border-radius: 15px;
  font-size: 12px;
}

.activate-guarantees {

}

.activate-guarantees input{
  border-radius: 8px;
  height: 44px;
  border: 1px solid #EFEFEF;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
  box-sizing: border-box;
}
.activate-guarantees button {
  background: #383838;
  color: white;
}


.extended-input-wrapper{
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}


.input-wrapper{
  width: 100%;
  margin-bottom: 10px;
}

.input-wrapper label{
  display: block;
  margin-bottom: 4px;
}

.input-wrapper input{
  border-radius: 8px;
  height: 44px;
  border: 1px solid #cbc9c9;
  margin-top: 10px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.extended-input-wrapper input{
  border-radius: 8px;
  height: 44px;
  border: 2px solid #DEDAF6;
  margin-top: 10px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.input-wrapper input:active {
  outline: none;
}

.input-wrapper input:focus {
  outline: none;
}

.extended-input-wrapper input:active {
  outline: none;
}

.extended-input-wrapper input:focus {
  outline: none;
}

.btns-wrapper {
  display: flex;
  justify-content: space-between;
}

.btns-wrapper button{
  flex: 1;
}

.btns-wrapper button:nth-of-type(1){
  margin-right: 15px;
}

.btns-wrapper button:nth-of-type(2){
  background: #383838;
  color: white;
}

.form-title{
  color: #000000;
  margin: 16px 0;
  font-size: 16px;
  font-weight: 600;
}

.select-wrapper {
  width: 100%;
  margin-bottom: 10px;
}
.select-wrapper label{

}
.select-wrapper select{
  border-radius: 8px;
  height: 44px;
  border: 1px solid #cbc9c9;
  margin-top: 10px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.top-block {
  width: 100%;
  /*background: url("../img/main/top.png");*/
  height: 100px;
  background-size: cover;
  background-position: left bottom;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
}

@media (min-width: 360px) {
  .top-block {
    height: 160px;
  }

}

@media (min-width: 433px) {
  .top-block {
    height: 180px;
  }

}
@media (min-width: 518px) {
  .top-block {
    height: 200px;
  }

}

.top-block img{
  width: 100%;
  height: 200px;
  border-radius: 0 0 20px 20px;
  object-fit: cover;
}

.padding-block {
  padding: 0 16px;
}


.main-discount-btn {
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 8px;
  box-sizing: border-box;
  border: unset;
  background: #CBEABF;
}
.main-discount-btn-active {
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 8px;
  box-sizing: border-box;
  border: 2px solid #CBEABF;
  background: unset;
}
.main-discount-btn-left {

}
.main-discount-btn-right {
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: flex-start;
  padding-left: 10px;
}

.main-discount-btn-right span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  color: #217400;
  text-align: left;
}

.main-discount-btn-right span:nth-of-type(2) {
  font-size: 13px;
  font-weight: 400;
  color: #217400;
  text-align: left;
}

.categories-item-count-block {
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 20px;
  background: #EEECFA;
  z-index: 2;
  text-align: right;
  padding-right: 9px;
  box-sizing: border-box;
}

.categories-item-count-icon-top {
  position: absolute;
  right: 29px;
  top: 0;
}

.categories-item-count-icon-bottom {
  position: absolute;
  right: 0;
  top: 20px;
}
.categories-item-count-block span {
  font-size: 13px;
  color: #85808F;
}

.categories-item button {
  padding: 4px 8px;
  border-radius: 4px;
  border: unset;
  color: #4E2B96;
  background: white;
}

.categories-item-discount {

}

.categories-item-discount-wrapper {
  /*padding: 2px 6px;*/
  /*background: white;*/
  /*display: inline-block;*/
  position: relative;
  z-index: 2;
}

.categories-item-discount-wrapper p:nth-of-type(1) {
  font-size: 13px;
  font-weight: 400;
  color: black;
}
.categories-item-discount-wrapper p:nth-of-type(2) {
  font-size: 13px;
  font-weight: 400;
  color: black;
}

.categories-item-discount-icon1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.my-discount-icon1 {
  position: absolute;
  bottom: 0;
  left: -16px;
  z-index: 1;
  width: 132px;
  height: 132px;
}
.categories-item-discount-icon2 {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.my-discount-icon2 {
  position: absolute;
  right: 0;
  bottom: 0;
}


.feedback-wrapper {
  background: #F4F3FC;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 10px;
}
.feedback-wrapper h1{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
}

.feedback-wrapper-emoji {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

}
.feedback-wrapper-emoji-item {

}
.feedback-wrapper textarea {
  border: 2px solid #DEDAF6;
  width: 100%;
  display: block;
  min-height: 70px;
  margin-bottom: 17px;
  border-radius: 7px;
}
.feedback-wrapper button {
  font-size: 14px;
  font-weight: 500;
  color: white;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: #F15457;
  border: unset;
}

.menu-wrapper-header > h1 {
  font-size: 20px;
  font-weight: 700;
}

.close-menu-btn {
  color: #85808F;
  background: #EEECFA;
  height: 44px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: unset;
}

.wrapper-flex {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.list-item-bottom-hide-block {
  display: none;
  background: #EEECFA;
  padding: 10px;
}
.list-item.is-open .list-item-bottom-hide-block {
  display: block;
}

.list-item-bottom-hide-block button{
  padding: 6px 10px;
  border: unset;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background: #F15457;
  border-radius: 4px;
}

.bottom-arrow {
  position: absolute;
  right: 10px;
  top: 20px;
}

.list-item-wrapper {
  padding: 10px;
}

.list-item.is-open .bottom-arrow {
  transform: rotate(180deg);
}

.bottom-btns-wrapper {
  padding: 20px 16px;
  display: flex;
}

.bottom-btns-wrapper .simple-btn{
  background: #EEECFA;
  font-size: 14px;
  font-weight: 500;
  color: #85808F;
  border: unset;
  margin-right: 10px;
  padding: 13px 33px;
  border-radius: 8px;
}

.bottom-btns-wrapper .red-btn{
  background: #F15457;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: unset;
  padding: 13px 33px;
  border-radius: 8px;
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-btn{
  background: #F15457;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: unset;
  padding: 13px 33px;
  border-radius: 8px;
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-block-logo {
  position: absolute;
  top: 13px;
  left: 16px;
}

.profile-input-wrapper {
  margin-bottom: 17px;
}
.profile-input-wrapper input::placeholder{
  color: #85808F;
}
.profile-input-wrapper input{
  width: 100%;
  height: 42px;
  color: black;
  border: 2px solid #DEDAF6;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
}

.profile-input-wrapper .input{
  width: 100%;
  height: 42px;
  color: black;
  border: 2px solid #DEDAF6;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
}


.yellow-time {
  background: #FF9900;
  color: white !important;
  padding: 1px 4px;
  border-radius: 10px;
}

.reg-for-services-title {
  color: #85808F;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
}
.reg-for-services-address {
  font-size: 13px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 16px;
}
.build-a-route {
  margin-bottom: 16px;
  background: #F4F3FC;
  border: unset;
  border-radius: 4px;
  padding: 4px 8px;
  min-height: 26px;
  color: black;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}

.reg-for-services-val {
  margin-bottom: 17px;
  font-size: 14px;
  font-weight: 500;
}

.cancelation-yellow-block {
  background: #FF9900B2;
  padding: 3px 5px;
  color: white;
  font-size: 12px;
}

.cancel-order-bottomshet-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box
}

.weel-storage-bottomshet-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box
}

.cancel-order-bottomshet-container {
  overflow: scroll;
  flex: 1;
  margin-bottom: 10px
}

.cancel-order-bottomshet-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
}
.cancel-order-bottomshet-subtitle {
  font-size: 13px;
  font-weight: 400;
  color: #85808F;
  margin-bottom: 17px;

}


.reasons-list-item {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  align-items: center;
}
.custom-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #D1CFD4;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-checkbox-active {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #F22F22;
}


.reasons-list-item span {
  font-size: 14px;
  font-weight: 500;
}


.cancel-order-textarea:active, .cancel-order-textarea:focus {
  outline: none;
}

.cancel-order-textarea::placeholder {
  color: #85808F;
}
.cancel-order-textarea {
  border: 2px solid #DEDAF6;
  display: block;
  min-height: 70px;
  margin-bottom: 17px;
  border-radius: 7px;
  width: calc(100% - 4px);
  padding: 10px;
  box-sizing: border-box;
}

.react-modal-sheet-container  {
  transform: translateY(0px) translateZ(0px) !important;
}


.cancel-success-wrapper {
   display: flex;
  flex: 1;
  flex-direction: column;
}
.cancel-success-message {
  padding: 0 15px;
   height: 50px;
  font-size: 12px;
  font-weight: 400;
  color: #383838;
  background: #EEF8EA;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 10px;
}

.wheel-storage-products-list {

}
.wheel-storage-products-list-item {
  margin-bottom: 15px;
  display: flex;
}

.wheel-storage-products-list-item-left {
  margin-right: 5px;
}
.wheel-storage-products-list-item-right {
  display: flex;
  flex-direction: column;
}

.wheel-storage-products-list-item-right span:nth-of-type(1) {
    font-size: 14px;
   font-weight: 500;
  color: black;
}

.wheel-storage-products-list-item-right span:nth-of-type(2) {
  font-size: 12px;
  font-weight: 400;
  color:#217400;
}

.wheel-storage-title {
  color: #85808F;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
}
.wheel-storage-address {
  font-size: 13px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 16px;
}

.wheel-storage-val {
  margin-bottom: 17px;
  font-size: 14px;
  font-weight: 500;
}




.weel-storage-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.weel-storage-message {
  padding: 0 15px;
  font-size: 12px;
  font-weight: 400;
  color: #383838;
  background: #EEF8EA;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 10px;
  flex: 1;
  position: relative;
}


.weel-storage-message.red {
  background: #FCE9E8;
}

.weel-storage-message.yellow {
  background: #FFF5E5;
}

.weel-storage-bottom-icon {
  position: absolute;
  bottom: -11px;
}

.nodata-subtitle {
  margin-top: 24px;
  font-size: 15px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 10px;
}

.extended-toggle-wrapper {
  padding: 10px;
  background: #EEECFA;
  display: none;
  transition: 0.5s;
}

.extended-toggle-wrapper.is-open {
  padding: 10px;
  background: #EEECFA;
  display: block!important;
}

.extended-toggle-wrapper-title {
  font-size: 13px;
  font-weight: 400;
  color: #85808F;
  margin-bottom: 4px;
}
.extended-toggle-wrapper-val {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 10px;
}





.extended-bottomshet-wrapper {
  display: flex;
  /*flex: 1;*/
  height: 100%;
  flex-direction: column;

}

.extended-bottomshet-container {
  display: flex;
  /*height: 100%;*/
  width: 100%;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  flex: 1;
  overflow: scroll;
}

.extended-bottomshet-main-title {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 18px;
}


.extended-subtitle{
  margin: 17px 0;
  font-size: 14px;
  font-weight: 500;
}

.extended-phone-icon{
  position: absolute;
  right: 9px;
  top: 6px;
  bottom: 0;
  margin: auto;
}
#dropdown:active, #dropdown:focus {
  outline: none;
}
#dropdown {
  appearance: none; /* Скрыть стандартный вид */
  -webkit-appearance: none; /* Для Safari */
  -moz-appearance: none; /* Для Firefox */
}

.my-doscount-number {
  color: #DEDAF6;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 4px;
}

.my-doscount-phone {
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
}



.my-discount-wrapper {
  padding: 6px 10px;
  background: white;
  display: inline-block;
  position: relative;
  z-index: 2;
  border-radius: 5px;
}

.my-discount-wrapper p:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-bottom: 12px;
}
.my-discount-wrapper p:nth-of-type(2) {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.my-discount-wrapper p:nth-of-type(3) {
  font-size: 12px;
  font-weight: 400;
  color: #383838;
  text-align: right;
}




.my-discount-nodata-subtitle {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 800;
}
.build-a-route {

}
.my-discount-nodata-wrapper {
  padding: 16px;
  background: #DEDAF6;
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 98px;
  box-sizing: border-box;
  border-radius: 10px;
}
.my-discount-nodata-left {

}
.my-discount-nodata-left p:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
.my-discount-nodata-left p:nth-of-type(2) {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}
.my-discount-nodata-left p:nth-of-type(3) {

}
.my-discount-nodata-right {

}
.my-discount-nodata-right img{
  width: 144px;
  position: absolute;
  right: 0;
  top: -15px;
}

.my-discount-nodata-ul {
  padding-left: 20px;
  margin-bottom: 17px;
}

.my-discount-nodata-ul li{
  margin-bottom: 6px;
}

.settings p {
  font-size: 13px;
}

.slider-pagination-item {

}

.slider-pagination-item img{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.slider-wrapper {

}
.slider-wrapper-container {

}

.slider-wrapper-container-header {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  z-index: 2;

}
.slider-wrapper-container-header svg{
  margin-bottom: 15px;
}
.slider-wrapper-container-header p {
  font-size: 27px;
  font-weight: 600;
  color: white;
}


.slider-wrapper-container-bg {
  background: linear-gradient(180deg, #1F3C73 2.1%, #E38C67 60.1%, rgba(217, 217, 217, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 242px;
}


.error-modal {
  position: absolute;
  height: 100vh;
  z-index: 99;
  width: 100%;
  box-sizing: border-box;
}
.success-modal {
  position: absolute;
  height: 100vh;
  z-index: 99;
  width: 100%;
  box-sizing: border-box;
  background: #00000061;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-modal-container {
  width: 300px;
  height: 166px;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feedback-wrapper-emoji-item.selected {
  transform: scale(1.2);
}
.feedback-textarea {
  padding: 3px;
  box-sizing: border-box;
}

.feedback-textarea:focus {
  outline: none;
}


.feedback-success-wrapper {
  background: #eff6ec;
  minHeight: 186px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.feedback-success-wrapper-span1.error{
  color: #F22F22;
}
.feedback-success-wrapper-span1{
  margin-top: 36px;
  margin-bottom: 24px;
  display: block;
}
.feedback-success-wrapper-span2{
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 24px;

}

.feedback-success-wrapper button {
  width: 100%;
  height: 44px;
  background: #F15457;
  border: unset;
  font-size: 14px;
  font-weight: 400;
  color: white;
}



.feedback-error-wrapper {

}
.feedback-error-wrapper-span1 {

}
.feedback-error-wrapper-span2 {

}
.feedback-error-wrapper button {

}





/* Основной контейнер */
.login_form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

/* Заголовок */
.login_form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

/* Подзаголовок */
.login_form-subtitle {
  text-align: center;
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

/* Метка и ввод */
.login_form-label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

.login_input-container {
  position: relative;
  margin-bottom: 20px;
}

.login_form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.login_form-input:focus {
  border-color: #40a8e6;
}

.login_clear-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 16px;
  color: #aaa;
  cursor: pointer;
}

.login_clear-button:hover {
  color: #333;
}

/* Чекбокс */
.login_checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.login_form-checkbox {
  margin-right: 10px;
  cursor: pointer;
}

.login_form-checkbox-label {
  font-size: 14px;
  color: #333;
}

.login_personal-data-link {
  color: #f33;
  text-decoration: none;
}

.login_personal-data-link:hover {
  text-decoration: underline;
}

/* Кнопка отправки */
.login_submit-button {
  width: 100%;
  padding: 10px;
  background-color: #e94b35;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login_submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.login_submit-button:not(:disabled):hover {
  background-color: #d13f2c;
}


.login_timer {
  margin-top: 10px;
  color: #e22f22;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

.login_back-button {
  color: #e22f22;
  border: unset;
  border-bottom: 1px dashed ;
  background: unset;
  margin: 10px 0;
}


.login_resend-button {
  color: black;
  font-weight: bold;
  border: unset;
  border-bottom: 1px dashed ;
  background: unset;
  margin: 10px 0;
}


/* Общий стиль для чекбокса */
.login_form-checkbox {
  appearance: none; /* Убираем стандартный стиль браузера */
  width: 20px; /* Размер */
  height: 20px; /* Размер */
  border: 1px solid black; /* Красная рамка */
  border-radius: 4px; /* Скругленные углы */
  outline: none; /* Убираем обводку при фокусе */
  cursor: pointer; /* Указатель при наведении */
}

/* Стили для состояния checked */
.login_form-checkbox:checked {
  background-color: red; /* Заливка красным */
  border-color: red; /* Рамка красного цвета */
  position: relative;
}

.login_form-checkbox:checked::after {
  content: ''; /* Создаем галочку */
  position: absolute;
  width: 4px;
  height: 8px;
  border: solid white; /* Белая галочка */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);

  top: 3px;
  left: 6px;
}
